import { firestore } from "utils/firebase";
import { getItemNameFromManufacturer } from "utils/helpers";
import { fetchFromStockItems } from "./customers-service";
import { markDToolsItemReturned } from "./dtools-service";
import { updateLocationQuantity, updateQuantity } from "./transactions-service";
import { updateLocationPart } from "./parts-service";
import firebase from "firebase/app";

export const getPurchaseOrderItems = (
  companyId,
  customerId,
  setPurchaseOrderItems
) => {
  return (
    firestore
      .collection("companies")
      .doc(companyId)
      .collection("purchaseOrders")
      .where("customerId", "==", customerId)
      // .orderBy('itemFullNameLowercase')
      .onSnapshot(function (querySnapshot) {
        const poItems = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.deleted) return;
          poItems.push({
            ...data,
            id: doc.id,
          });
        });

        setPurchaseOrderItems(poItems);
      })
  );
};

export const getOpenPurchaseOrderItems = (
  companyId,
  setPurchaseOrderItems,
  parts,
  manufacturers,
  startDate,
  endDate,
  status
) => {

  let closed = status === "closed" ? true : false;

  let query = firestore
    .collection("companies")
    .doc(companyId)
    .collection("purchaseOrders")
    .where("timeCreated", ">=", startDate)
    .where("timeCreated", "<=", endDate)

  if (status !== 'all') {
    query = firestore
      .collection("companies")
      .doc(companyId)
      .collection("purchaseOrders")
      .where("receivedInFull", "==", closed)
      .where("timeCreated", ">=", startDate)
      .where("timeCreated", "<=", endDate)
  }
  // .orderBy('itemFullNameLowercase')



  query.get()
    .then(function (querySnapshot) {
      const poItems = [];
   
      querySnapshot.forEach((doc) => {
        
        const data = doc.data();
        if (data.deleted) return;
        let item = {
          ...data,
          id: doc.id,
        };

        if (item.receivedQuantity !== item.quantity || item.isManuallyClosed || item?.receivedInFull === undefined) {
          doc.ref.update({
            receivedInFull:
              item.quantity === 0 || item.isManuallyClosed === "true"
                ? true
                : false,
          });
        }
        const {returnedQuantity = 0} = item
        if (item.quantity - returnedQuantity === 0) return;
        if (item.quantity === 0) return;
        if (item.receivedInFull===false && item.isManuallyClosed === "true") return;
        if (item.receivedInFull===false && item.receivedQuantity === item.quantity) return;

        if (!item.itemFullName) {
          let part = parts.find((x) => x.id === item.itemListId);
          if (part) {
            item.parentRefID = part.parentRefID;

            if (item.parentRefID) {
              const parent =
                manufacturers &&
                manufacturers.find((x) => x.id === item.parentRefID);

              item.manufacturer = parent ? parent.model : "";
              item.lowercaseMfg = parent ? parent.model.toLowerCase() : "";
            }

            item.itemFullName = item.manufacturer
              ? item.manufacturer + ": " + part.model
              : part.model;
          } else {
            return;
          }
        }
        poItems.push(item);
      });

      // console.log("po items", poItems.length);
      setPurchaseOrderItems(poItems);
    })
    .catch((e) => {
      console.log("error getting open purchase orders", e);
    });
};

export const checkAlertStatus = async (
  companyId,
  poDocId,
  userId,
  setAlert
) => {
  return firestore
    .collection("companies")
    .doc(companyId)
    .collection("purchaseOrders")
    .doc(poDocId)
    .collection("alerts")
    .doc(userId)
    .get()
    .then(function (userAlertDoc) {
      if (userAlertDoc.exists) {
        setAlert(userAlertDoc.data().alert);
      } else {
        setAlert(false);
      }
    });
};

export const setAlert = async (companyId, poDocId, userId, alert, setAlert) => {
  return firestore
    .collection("companies")
    .doc(companyId)
    .collection("purchaseOrders")
    .doc(poDocId)
    .collection("alerts")
    .doc(userId)
    .set({ alert })
    .then(() => {
      setAlert(alert);
    });
};

export const getCustomerStagedItems = async (
  company,
  customer,
  parts,
  manufacturers,
  setStagedParts,
  returning
) => {
  return firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .where("customerId", "==", customer.id)
    .get()
    .then((querySnapshot) => {
      const stagedItems = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.deleted) return;
        const item = {
          docRef: doc.ref,
          ...data,
          id: doc.id,
        };
        if (item.rejected) return;
        if (returning) {
          const returnedQuantity = item?.returnedQuantity || 0;
          if (item?.deliveredQuantity && (item?.deliveredQuantity - returnedQuantity) > 0) {
            item.availableQuantity = item?.deliveredQuantity;
            item.quantityToDeliver = item?.deliveredQuantity;

            stagedItems.push(item);
          } else {
            // console.log('test', item)
          }
        } else {
          if (
            !item?.deliveredQuantity ||
            item?.quantity - item?.deliveredQuantity > 0
          ) {
            item.availableQuantity = item?.deliveredQuantity
              ? item?.quantity - item?.deliveredQuantity
              : item?.quantity;
            item.quantityToDeliver = item.availableQuantity;
            stagedItems.push(item);
          }
        }
      });

      // setStagedItems(sorted)

      const temp = [];
      stagedItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.itemListId);
        if (part && part.parentRefID) {
          const parent = parts.find((x) => x.id === part.parentRefID);
          //   console.log('parent', parent?.model);
          if (parent) {
            part.manufacturer = parent.model;
          } else {
            console.log("no parent found", part.parentRefID);
          }
        }
        item.itemFullName = part
          ? part.manufacturer
            ? `${part.manufacturer}: ${part.model}`
            : part.model
          : item?.itemFullName;
        temp.push(item);
      });
      const sorted = temp.sort((a, b) =>
        a.itemFullName > b.itemFullName ? 1 : -1
      );
      if (returning) {
        fetchFromStockItems(company.id, customer.id, (stockItems) => {
          // console.log("yay", stockItems?.length);
          const stockParts = [];
          stockItems.forEach((item) => {
            const part = parts.find((x) => x.id === item.partID);
            if (part) {
              item.itemFullName = getItemNameFromManufacturer(
                part,
                manufacturers
              );
            } else {
              // console.log(' no part? ', item)
              item.itemFullName = getItemNameFromManufacturer(
                item,
                manufacturers
              );
            }
            item.availableQuantity = item?.qty;
            item.quantityToDeliver = item?.qty;
            item.deliveredQuantity = item.qty;
            item.deliveredBy = item.user;
            item.deliveredDate = item.date;
            item.timeCreated = item.date;
            item.stock = true;
            const find = sorted.find((x) => x?.historyDocRef === item?.id);

            //stock || staged and not delivered
            if (!item?.staged || (find && !find?.deliveredQuantity)) {
              stockParts.push(item);
            }
          });
          // console.log("yay2", stockParts, sorted);
          setStagedParts(stockParts.concat(sorted));
        });
      } else {
        setStagedParts(sorted);
      }
    })
    .catch((e) => {
      console.log("error getting customer staged items", e);
    });
};

export const getCompanyStagedItems = (company) => {
  return firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .where("deliveryComplete", "==", false)
    .where("receivedQuantity", ">", 0);
};

export const deletePO = (company, item) => {
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .doc(item.id)
    .delete();
};

export const movePOToNewCustomer = (company, item, newCustomer, onSuccess) => {
  const data = {
    ...item,
    id: null,
    customerId: newCustomer.id,
    linkedPO: item.id,
  }
  console.log('moving po to new customer', data)
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .add(data)
    .then(() => {
      firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .doc(item.id)
        .update({
          deleted: true,
        })
        .then(() => {
          onSuccess();
        });
    });
};

export const movePOToStock = (
  company,
  currentUser,
  purchaseOrder,
  customer,
  newLocation,
  quantity,
  onSuccess,
  locationParts
) => {
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .doc(purchaseOrder.id)
    .update({
      deliveredBy: currentUser.id,
      deliveredDate: new Date(),
      deliveredQuantity: firebase.firestore.FieldValue.increment(quantity),
      returnedQuantity: firebase.firestore.FieldValue.increment(quantity),
    })
    .then(() => {
      const history = {
        manufacturer: "",
        model: "",
        from: customer.fullName || customer?.name,
        fromLocationId: "",
        fromClientId: customer.id,
        to: newLocation.name,
        toLocationId: newLocation.id,
        toClientId: "",
        date: new Date(),
        qty: quantity,
        user: currentUser.id,
        received: false,
        approved: false,
        partID: purchaseOrder.itemListId,
        custom: false,
        customerRef: [customer.id],
        rejected: false,
      };
      console.log("history", history);
      firestore
        .collection("companies")
        .doc(company.id)
        .collection("history")
        .add(history)
        .then(() => {
          if (company.dToolsKey) {
            const selectedItem = {
              customerId: customer.id,
              itemListId: purchaseOrder.itemListId,
              quantityToDeliver: quantity,
            };
            markDToolsItemReturned(
              company,
              selectedItem,
              currentUser?.firstName + " " + currentUser?.lastName
            );
          }
          updateQuantity(company, history);
          updateLocationQuantity(
            company,
            locationParts,
            {
              id: purchaseOrder.itemListId,
              lowercaseMfg: "",
              lowercaseModel: "",
            },
            history,
            false
          );
          onSuccess();
        })
        .catch((e) => {
          console.log("error moving po to stock creating history", e);
        });
    })
    .catch((e) => {
      console.log("error moving po to stock updating PO", e);
    });
};

export const moveStockTransactionToNewCustomer = (
  company,
  item,
  newCustomer,
  onSuccess
) => {
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("history")
    .doc(item.id)
    .update({
      to: newCustomer.fullName || newCustomer.name,
      toClientId: newCustomer.id,
      customerRef: [newCustomer.id],
    })
    .then(() => {
      firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .doc(item.poId)
        .update({
          customerFullName: newCustomer.fullName || newCustomer.name,
          customerId: newCustomer.id,
        })
        .then(() => {
          onSuccess();
        });
    });
};
